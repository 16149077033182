import { render, staticRenderFns } from "./ProfilesList.vue?vue&type=template&id=24daad78&scoped=true&"
import script from "./ProfilesList.vue?vue&type=script&lang=js&"
export * from "./ProfilesList.vue?vue&type=script&lang=js&"
import style0 from "./ProfilesList.vue?vue&type=style&index=0&id=24daad78&prod&lang=scss&scoped=true&"
import style1 from "./ProfilesList.vue?vue&type=style&index=1&id=24daad78&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24daad78",
  null
  
)

export default component.exports